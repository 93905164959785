#root {
  color: white;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  padding: 50px;
  height: 100%!important;
}

.console-output {
  overflow-x: hidden;
  overflow-x: scroll;
  width: 100%;
  height: 95%;
}
.console {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  width: 60%;
  padding: 20px;
  background-color: #1a1d26;
  border: solid 5px transparent;
  border-radius: 25px;
}
.prompt {
  color: #264a79;
}


.input-prompt {
  width: 100%;
  height: 10px;
  background-color: black;
  flex: 1;
  padding: .75%;
}

input {
  color: white;
  background-color: black;
  border: none;
  outline: none;
  width: 80%;
  font-size: 1em;
}

span.wb-gh{
  width: 35px;
}